import React, { useState } from "react"
import { Carousel, Container } from "react-bootstrap";
import './ProductBanner.scss'
import PlayVideo from "../../Play/PlayVideo"
import ScrollAnimation from "react-animate-on-scroll";
import { TeamMemberDetail } from "../../../queries/common-use-query";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import FsLightbox from 'fslightbox-react';


const ProductBanner = (props) => {
    const [isPlay, setPlay] = useState(false);
    const property = props.data;
    const [toggler, setToggler] = useState(false);
    let ids = [];
    if(property.crm_negotiator_id) {
        ids.push(property.crm_negotiator_id)
    }
    if(property.negotiatorID) {
        ids.push(property.negotiatorID)
    }
    const { data, loading, error } = TeamMemberDetail(ids);
    const chunkSize = 2;
    const arr = property.images;
    return (
        <React.Fragment>
            <ScrollAnimation animateIn="fadeInUp" className="prop-card-wrap">
                <section className="property-card product-banner-n position-relative">
                    <div className="product-card-img img-zoom h-auto">
                        <Carousel className="w-100">
                            {arr && arr.map((img, i) =>
                                {
                                    let processedImages = JSON.stringify({});
                                    if (property?.imagetransforms?.images_Transforms) {
                                        processedImages = property.imagetransforms.images_Transforms;
                                    }
                                    return (
                                        <Carousel.Item key={i} className="w-100 position-relative" id={"prop-img"+i}>
                                            <ImageTransform
                                                imagename="property.images.detail"
                                                attr={{ alt: '', class:'' }}
                                                imagesources={img.url}
                                                renderer="srcSet"
                                                imagetransformresult={processedImages}
                                                id={property.id}
                                            />
                                            <div className="search-n" onClick={() => setToggler(!toggler)}> <i className="icon-zoom"></i>{i+1}/{arr.length}</div>
                                        </Carousel.Item>
                                    )
                                }
                            )}
                        </Carousel>
                        {property.virtual_tour && <div className="product-btn">
                            <strong className="video-btn" >
                                <i className="icon-video" onClick={e => props.openVirtualTour(true)}/>
                            </strong>
                        </div>}
                    </div>
                    <div className="product-card-details">
                        <h1>{property.display_address}</h1>
                        <div className="price-details">
                            <span className="price-list order-md-2">{property.price_qualifier} {property.price_qualifier !== "Price on Application" ? `£${property.price.toLocaleString()}`: ""}</span>
                            <span className="sm-title order-md-1">{property.title}</span>
                        </div>
                        <div className="product-icon">
                            <span className="details">
                                <i className="icon-bed-room d-md-none"/>
                                <span className="quantity"> {property.bedroom}</span>
                                <span className="title d-none d-md-block">Bedrooms</span>
                            </span>
                            <span className="details">
                                <i className="icon-bath-room d-md-none"/>
                                <span className="quantity"> {property.bathroom}</span>
                                <span className="title d-none d-md-block">Bathrooms</span>
                            </span>
                            <span className="details hide-border">
                                <i className="icon-reception d-md-none"/>
                                <span className="quantity">{property.reception}</span>
                                <span className="title d-none d-md-block">Receptions</span>
                            </span>
                            {/* <span className="sm-text text d-none d-md-block">
                                Private Gated Development
                            </span> */}
                        </div>
                        <div className="d-xl-block buttons prop-viewing-cta">
                            <a onClick={props.openEnquiryForm} className="btn btn-primary">
                                Book Viewing
                            </a>
                        </div>
                        {(data && data.teams.length > 0 ) &&  data.teams.map((team, t) => 
                            <div className="images prop-img-cont mb-3" key={t}>
                                <div className="author-img author-img-prop">
                                    <img src={team.Tile_Image.url} className="p-0 border-o" alt="img" />
                                </div>
                                <div className="about-img">
                                    <h4>{team.Name}</h4>
                                    <a href="tel:+07891219762" className="tel">{team.Phone}</a>
                                </div>
                            </div> 
                        )}
                    </div>
                </section>
            </ScrollAnimation>
            {<PlayVideo
                isOpen={isPlay}
                isCloseFunction={setPlay}
                videoId="V8ksSGxo6no"
                isAutoPlay={1}
            />}
             <FsLightbox
                toggler={toggler}
                sources={arr.map(img => img?.url)}
                type='image'
                // onClose={() => setToggler(false)}
            />
        </React.Fragment>
    )
};
export default ProductBanner