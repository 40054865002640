import React, { useEffect, useState } from "react"
import { Link } from "@StarberryUtils"
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import ScrollAnimation from 'react-animate-on-scroll';
import './SimilarProperties.scss';
import { GetPropertiesList } from "../../../queries/common-use-query";
import axios from "axios";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";

const SimilarProperties = (props) => {
    const [propItems, setPropItems] = useState([]);
    const strapiconfig = {
        headers: {
            Authorization:
                `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
        },
    }

    const { property } = props;
    useEffect(() => {
        // similar properties
        let similar_property_id = property.id;
        let type = property.search_type === "sales" ? "sales" : "let";
        let url = process.env.GATSBY_STRAPI_SRC + `/stb-lists/item/property-details-${type}?pid=` + similar_property_id.replace("/#","").split("?")[0];
        getItems(url)
        // similar properties
    }, []);

    const getItems = async url => {
        try {
            const { data } = await axios.get(url, strapiconfig)
            if (data && data.length > 0) {
                setPropItems(data)
            }
        } catch (error) {
            console.error(error)
        }
    }
    const settings = {
        dots: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,

                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,

                },
            },
            {
                breakpoint: 377,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,

                },
            },
        ],
    }
    // const { data, loading, error } = GetPropertiesList(10);
    return (
        <section className="similar-properties">
            {propItems.length > 0 && <Container>
                <span class="sub-content">You may also like</span>
                <ScrollAnimation animateIn='fadeInUp'>
                    <h3>Similar Properties</h3>
                    <Slider className={`slick-arrow ${propItems.length <= 2 ? "similar-single-properties" : ""}`} {...settings}>
                        {propItems.map((property, i) => {
                            let processedImages = JSON.stringify({});
                            if (property?.imagetransforms?.images_Transforms) {
                                processedImages = property.imagetransforms.images_Transforms;
                            }
                            return (
                                <div className="slider-wrap" key={i}>
                                    <Link to={`/property-${property.search_type === "sales" ? "for-sale" : "to-rent"}/${property.slug}-${property._id}`}>
                                        <div class="slider position-relative">
                                            <div className="home-img">
                                                {/* {property.images.length > 0 && <picture>
                                                    <source media="(min-width:1200px)" srcSet={property.images[0].srcUrl.replace("http", "https")} />
                                                    <img src={property.images[0].srcUrl.replace("http", "https")} alt="img" />
                                                </picture>  }                              */}
                                                {property.images.length > 0 && 
                                                    <ImageTransform
                                                        imagename="property.images.featured"
                                                        attr={{ alt: '', class:'' }}
                                                        imagesources={property.images[0].url}
                                                        renderer="srcSet"
                                                        imagetransformresult={processedImages}
                                                        id={property._id}
                                                    />
                                                }   
                                                <div className="home-content props-similar">
                                                    <h4>{property.display_address}</h4>
                                                    <span className="price">{property.price_qualifier} £{property.price.toLocaleString()}</span>
                                                </div>
                                            </div>
                                            <button className="prop-card-btn">{property.status}</button>
                                        </div>
                                    </Link>
                                </div>
                            )}
                        )}     
                        {window.innerWidth > 1024 && <div className="slider-wrap"></div>}                  
                    </Slider>
                </ScrollAnimation>
            </Container> }
        </section>
    )
};
export default SimilarProperties
