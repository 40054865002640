import React, { useState, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "@StarberryUtils"
import { Container, Col, Row, Modal } from "react-bootstrap";
import img01 from '../../../images/product-details/contact-img.png';
import img02 from '../../../images/product-details/image-contact.png';
import './Features.scss';
import HTMLReactParser from "html-react-parser";
import ReactMarkdown from "react-markdown"
import ContactUsForm from "../../forms/contact-us-form";
import { OfficeDetail } from "../../../queries/common-use-query";
/**
 * Feature function component
 * @param {} props
 * @returns component
 */
const Features = (props) => {
    const top_ref = useRef();
    const anchorRef = useRef(); // creating ref for anchor tag
    const [isReadMore,setReadMore] = useState(false) // local state for less/expand readmore
    const [showForm, setShowForm] = React.useState(false);
    const [email, setEmail] = React.useState("");

    //Handle read more and less class
    // React.useEffect(() => {
    //     anchorRef.current.onclick = () => {
    //         setReadMore(!isReadMore)
    //     }
    // },[isReadMore]);

    const readLess = () => {
        setReadMore(!isReadMore);
        top_ref.current.scrollIntoView({behavior: 'smooth'});
    }

    const readMore = () => {
        setReadMore(!isReadMore);
    }

    const longDescription = (data) => {
        var text, long_desc, long_description = '';
        text = 'ADDITIONAL INFORMATION';
        long_desc = data;
        if(long_desc?.includes(text)){
            long_description = data?.replace(text, `${text} \n`);
        }else {
            long_description = data;
        }
        return HTMLReactParser(long_description)
    }

    const data1 = useStaticQuery(graphql`
        query {
            glstrapi {
			    siteConfig {
			      Address
			      Contact_Page_Intro
			      Office_Timing
			      Latitude
			      Longtitude
			      Contact_Page_Speak_With {
			        Name
			        Tile_Image {
			          url
			          alternativeText
			        }
			        Phone
			        Email
			        Designation
			      }
			      Email {
			        Email
			      }
			      Phone {
			        Phone_Number
			      }
			    }
            }
        }
    `);
    const { data, loading, error } = OfficeDetail(props.property?.office_crm_id);
    const long_description = props?.property?.long_description && longDescription(props?.property?.long_description);

    return (
        <section className="features-n"  ref={top_ref}>
            <Container>
                <Row>
                    <Col xl={7}>
                        <div className="w-100">
                            <div className="features-descripton">
                                <span class="sub-content">details</span>
                                <h3>Key Features & Description</h3>

                                <div  className={`feature-content ${isReadMore ? 'expand': '' }`}>
                                    <ReactMarkdown children={long_description.replaceAll("&#146;s", "")}/>
                                </div>
                                <div id="offset_top" className="more">
                                    { !isReadMore ?
                                        <span className="read-more" onClick={readMore}>Read More</span> :
                                        <span className="read-more" onClick={readLess}>Read Less</span>
                                    }
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xl={5} className="ml-xl-auto pl-md-0 d-xl-flex">
                        <div className="ml-xl-auto">
                            <div className="contact-wrapper img-zoom">
                                {loading? "": (data && data.offices.length > 0 ) &&  data.offices.map((office, t) =>
                                  <>
                                  <div className="contact-img d-none d-md-block">
                                      <picture>
                                          <source media="(min-width:1200px)" srcSet={office?.Image?.url} />
                                          <img src={office?.Image?.url} alt="img" />
                                      </picture>
                                  </div>
                                  <div className="contact-info">
                                      <h2>
                                          {HTMLReactParser(office?.Title)} Estate Agents
                                      </h2>
                                      <address className="d-none d-xl-block">
                                          {HTMLReactParser(office?.Address)}
                                      </address>
                                      <span className="tel">
                                          Tel:<a href={`tel:${office?.Mobile}`}> {office?.Mobile}</a>
                                      </span>
                                      <span className="mail">
                                          <a onClick={() => { setEmail(office?.Email); setShowForm(true); }}>{office.Email}</a>
                                      </span>
                                      <Link to="/book-a-valuation" className="btn">
                                          book a property valuation
                                      </Link>
                                  </div>
                                  </>
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Modal show={showForm} onHide={() => setShowForm(false)} className="enquire-form contact-modal" centered>
                <Modal.Header closeButton>
                    <Modal.Title className="px-0">Contact us</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ContactUsForm email={email}/>
                </Modal.Body>
            </Modal>
            </section>

    )
};
export default Features
