import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../scss/grunticon.scss";
import "../scss/bootstrap-overrides.scss";
import "../scss/global.scss";
import "../scss/custom.scss";
import { Header } from "../components/Header/Header";
import { Footer } from "../components/Footer/Footer";
import '../components/Static/AboutPage/About.scss';
import {  Modal, Carousel } from "react-bootstrap";
import { PropertyDetails, OfficeDetail } from "../queries/common-use-query";
import ProductBanner from "../components/ProductDetails/ProductBanner/ProductBanner";
import MenuList from "../components/ProductDetails/MenuList/MenuList";
import Features from "../components/ProductDetails/Features/Features";
import SimilarProperties from "../components/ProductDetails/SimilarProperties/SimilarProperties";
import ProductService from "../components/ProductDetails/ProductService/ProductService";
import PaymentDetails from "../components/calculators/mortgagecalc/index";
import Map from "../components/map/office-details-map";
import PlayVideo from "../components/Play/PlayVideo";
import Loader from "../components/loader";
import EnquiryForm from "../components/forms/enquire-form";
import BookAViewing from "../components/forms/book-a-viewing";
import NotFoundPage from "../pages/404";
import SEO from "../components/seo";
import { navigate } from "@reach/router";
import _ from "lodash";

const PropertyDetailsTemplate = (props) => {
    const propertyId = props.slug?.split('-').pop();
    const slug = props.slug?.split('-').slice(0, -1).join(" ");
    const { data, loading, error } = PropertyDetails(propertyId);
    const { data:officeDetail, loading:officeLoading, error:officeError } = OfficeDetail(data?.property?.office_crm_id);
    //console.log('officeEmail:',officeDetail?.offices[0]?.Email);
    const detailsRef = React.useRef(null);
    const mapRef = React.useRef(null);
    const bannerRef = React.useRef(null);
    const menuListRef = React.useRef(null);
    const headerRef = React.useRef(null);
    const [show, setShow] = React.useState(false);
    const [showTour, setVirtualTour] = React.useState(false);
    const [showView, setShowView] = React.useState(false);
    const [showEnquiry, setShowEnquiry] = React.useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const scrollFunction = () => {
        if(menuListRef.current != null) {
            let sticky = menuListRef.current.offsetTop;
            if(window.pageYOffset > sticky) {
                menuListRef.current.classList.add("sticky");
            } else {
                menuListRef.current.classList.remove("sticky");
            }
        }
    }

    useEffect(() => {
        function watchScroll() {
            window.addEventListener("scroll", scrollFunction);
        }
        watchScroll();
        return () => {
            window.removeEventListener("scroll", scrollFunction);
        };
    }, []);
    if(data && data.property && !data.property.publish) {
        return <NotFoundPage data={data}/>
    }
    var custumUrl = 0;
    if(data && data.property && data.property.virtual_tour){
        var virtualTour = data?.property?.virtual_tour.split("v=").length > 1 ? data?.property?.virtual_tour.split("v=")[1] : data?.property?.virtual_tour.split("youtu.be/")[1];
        if(virtualTour == undefined){
            custumUrl = data?.property?.virtual_tour
        }
    }
    return (
        <div className="wrapper wrapper-static">
            <SEO
                title={`${data?.property?.bedroom} bedroom ${_.upperFirst(data?.property?.building)} ${data?.property?.search_type === "sales" ? "for Sale" : "to Rent"} in ${data?.property?.area}`}
                description={`Book a viewing for this ${data?.property?.bedroom} bedroom ${_.upperFirst(data?.property?.building)} ${data?.property?.search_type === "sales" ? "for Sale" : "to Rent"} in ${data?.property?.area} with Bovingdons at £${data?.property?.price.toLocaleString()}. Contact us and get assisted for all your property needs`}
                image={data?.property?.images[0]?.url}
            />
            <div ref={headerRef}>
                <Header/>
            </div>
            {/* {data && data.property && <button className="btn btn-secondary" onClick={() => navigate(props.location.state.prevUrl, { state: { id: data.property.id } })}>Back to search results</button>} */}
            <div className="property-details">
                {loading ? <Loader/> :  (data && data.property && data.property.publish) &&
                    <div>
                        <div ref={bannerRef} className="property-dtl-banner">
                            <ProductBanner
                                data={data.property}
                                openVirtualTour={() => setVirtualTour(true)}
                                openEnquiryForm={() => setShowEnquiry(true)}
                            />
                        </div>
                        <div ref={menuListRef} className="">
                           {data.property &&
                                <MenuList
                                    brochure={data.property.brochure}
                                    floorplan={data.property.floorplan}
                                    detailsRef={detailsRef}
                                    mapRef={mapRef}
                                    status={data.property.status}
                                    goToMap={() => {
                                        let classList = Object.values(JSON.parse(JSON.stringify(menuListRef.current.classList)))
                                        let headerOffset;
                                        if(classList.includes("sticky")) {
                                            headerOffset = (ReactDOM.findDOMNode(menuListRef.current).offsetHeight+100) + ReactDOM.findDOMNode(headerRef.current).offsetHeight;
                                        } else {
                                            headerOffset = (ReactDOM.findDOMNode(menuListRef.current).offsetHeight+50)*2 + ReactDOM.findDOMNode(headerRef.current).offsetHeight;
                                        }
                                        let elementPosition = mapRef.current.getBoundingClientRect().top;
                                        let offsetPosition = elementPosition - headerOffset;
                                        window.scrollBy({
                                            top: offsetPosition,
                                            behavior: "smooth"
                                        });
                                    }}
                                    goToDetails={() => {
                                        let classList = Object.values(JSON.parse(JSON.stringify(menuListRef.current.classList)))
                                        let headerOffset;
                                        if(classList.includes("sticky")) {
                                            headerOffset = (ReactDOM.findDOMNode(menuListRef.current).offsetHeight+20) + ReactDOM.findDOMNode(headerRef.current).offsetHeight;
                                        } else {
                                            headerOffset = (ReactDOM.findDOMNode(menuListRef.current).offsetHeight+100) + ReactDOM.findDOMNode(headerRef.current).offsetHeight;
                                        }
                                        let elementPosition = detailsRef.current.getBoundingClientRect().top;
                                        let offsetPosition = elementPosition - headerOffset;
                                        window.scrollBy({
                                            top: offsetPosition,
                                            behavior: "smooth"
                                        });
                                    }}
                                    goToGallery={() => {
                                        bannerRef.current.scrollIntoView({ behavior: "smooth"})
                                    }}
                                    handleShow={handleShow}
                                    openFloorPlan={() => setShow(true)}
                                    openVirtualTour={() => setVirtualTour(true)}
                                    openBookAViewing={() => setShowView(true)}
                                    virtualTour={data.property.virtual_tour}
                                    url={props.location.href}
                                />
                            }
                        </div>
                        <div ref={detailsRef}>
                            <Features property={data.property} ref={detailsRef}/>
                        </div>
                        <div ref={mapRef}  className="mt-5">
                            <Map lat={data.property.latitude} log={data.property.longitude} Title={data.property.title}/>
                        </div>
                        {data.property.area && <ProductService area={data.property.area}/>}
                        {data.property.search_type === "sales" && <PaymentDetails propertyPrice={data.property.price} loadDuration={10}/>}
                        {data.property && <SimilarProperties property={data.property}/>}
                    </div>
                }
            </div>

            <Footer search={data?.property?.search_type === "sales" ? "Popular_Search_Sales" : "Popular_Search_Rent"} />
            {(data && data.property && data.property.floorplan.length > 0 && data.property.floorplan[0] != null) && <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton />
                <Modal.Body>
                    <Carousel>
                        {data.property.floorplan.map((img, i) =>
                            <Carousel.Item key={i}>
                                <picture>
                                    <img src={img?.srcUrl?.includes("https") ? img?.srcUrl : img?.srcUrl?.replace("http", "https")} alt="img" />
                                </picture>
                            </Carousel.Item>
                        )}
                    </Carousel>
                </Modal.Body>
            </Modal>}
            <Modal show={showView} onHide={() => setShowView(false)} className="enquire-form" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Book A Viewing</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BookAViewing postcode={data?.property.address?.postcode} officeEmail={officeDetail?.offices[0]?.Email}/>
                </Modal.Body>
            </Modal>
            <Modal show={showEnquiry} onHide={() => setShowEnquiry(false)} className="enquire-form" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Book Viewing</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EnquiryForm postcode={data?.property.address?.postcode} officeEmail={officeDetail?.offices[0]?.Email}/>
                </Modal.Body>
            </Modal>
            {(data && data.property && data.property.virtual_tour) && <PlayVideo
                isOpen={showTour}
                isCloseFunction={setVirtualTour}
                videoId={data?.property?.virtual_tour.split("v=").length > 1 ? data?.property?.virtual_tour.split("v=")[1] : data?.property?.virtual_tour.split("youtu.be/")[1]}
                custumUrl={custumUrl}
                isAutoPlay={1}
            />}
        </div>
    )
}

export default PropertyDetailsTemplate;
