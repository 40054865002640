import React from "react"
import { Container, Col, Row } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";
import './MenuList.scss';
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon
} from "react-share";

const MenuList = (props) => {
    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                siteConfig {
                    Facebook_Link
                    Instagram_Link
                    Twitter_Link
                    Footer_Bottom_Links {
                        Label
                        _id
                        Link {
                            _id
                            URL
                        }
                    }
                }
            }
        }
    `);
    const [shareUrl, setShareUrl] = React.useState(null);
    const trackerShare = (event) => {    
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Share - social icons',
          'formType': event + ' - ' +props.url,
          'formId': 'Share',
          'formName': 'Share',
          'formLabel': 'Share'
        });
    }
    return (
        <section className="menu-list-n">
            <Container>
                <Row>
                    <Col className="list-menu-section">
                        <ul className="list-menu">
                            <li>
                                <a onClick={props.goToDetails}>details</a>
                            </li>
                            <li>
                                <a onClick={props.goToMap}>map</a>
                            </li>
                            {props.virtualTour && <li>
                                <a onClick={props.openVirtualTour}>virtual tour</a>
                            </li>}
                            {/* <li>
                                <a onClick={props.goToGallery}>gallery</a>
                            </li> */}
                            {props.floorplan.length > 0 && <li>
                                <a onClick={props.openFloorPlan}>floorplan</a>
                            </li>}
                            {(props.brochure && props.brochure[0]?.srcUrl) && <li>
                                <a href={props.brochure[0].srcUrl} target="_blank">brochure</a>
                            </li>}
                            {props.status !== "Let" && props.status !== "Sold" && <li>
                                <a onClick={props.openBookAViewing}>book a viewing</a>
                            </li>}
                        </ul>
                    </Col>
                </Row>
                <div className="social-icons d-none d-xl-flex">
                    <a href="javascript:;" className="sm-content share-text"  onClick={() => setShareUrl(!shareUrl)}>
                        Share
                    </a>
                    {shareUrl && <div className="icons-list ml-3">
                        <FacebookShareButton onClick={()=>trackerShare('FacebookShareButton')} url={props.url} className="my-share-button facebook-share">
                            <FacebookIcon size={32} round={false} borderRadius={`10`} />
                        </FacebookShareButton>
                        <TwitterShareButton onClick={()=>trackerShare('TwitterShareButton')} url={props.url} className="my-share-button twitter-share">
                            <TwitterIcon size={32} round={false} borderRadius={`10`} />
                        </TwitterShareButton>
                        <LinkedinShareButton onClick={()=>trackerShare('LinkedinShareButton')} url={props.url} className="my-share-button linkedin-share">
                            <LinkedinIcon size={32} round={false} borderRadius={`10`} />
                        </LinkedinShareButton>
                        <WhatsappShareButton onClick={()=>trackerShare('WhatsappShareButton')} url={props.url} className="my-share-button whatsapp-share">
                            <WhatsappIcon size={32} round={false} borderRadius={`10`} />
                        </WhatsappShareButton>
                    </div>}
                </div>
            </Container>
            
       </section>
    )
};
export default MenuList